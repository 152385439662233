import React from 'react';
import { Breadcrumbs } from '@vp/breadcrumbs-react-component';
import { BoundedContent } from '@vp/swan';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { AuthProvider, useIdentity } from '@vp/digital-auth-lib';
import {
  RuntimeContextProvider,
  getClientEnvironment,
} from '@vp/digital-environment-lib';

import {
  RootLayout,
  HtmlAttributes,
  MainLayout,
} from '@vp/digital-site-layout-lib';
import { PricingContextProvider } from '@vp/digital-pricing-lib';
import { VISTAPRINT_TENANT } from '@vp/digital-care-lib';
import { BookendsWrapper } from '@vp/digital-bookends-lib';
import { SitewidePromoBar } from '@vp/sitewide-promo-bar/build/pure';
import { StyleKeys, ScriptKeys } from './src/swanKeys';
import { Helmet } from 'react-helmet';

const ConnectedProductPageWrapper = ({ pageContext, children }) => {
  const { locale, directAccessClientId, trackingConfiguration, urlMetadata } =
    pageContext;
  const { canonicalId } = useIdentity();

  return (
    <LocaleProvider rawLocaleIdentifier={locale}>
      <RuntimeContextProvider getEnvironment={getClientEnvironment}>
        <AuthProvider directAccessClientId={directAccessClientId}>
          <PricingContextProvider>
            <RootLayout swanStyleKeys={StyleKeys} swanScriptKeys={ScriptKeys}>
              <HtmlAttributes />
              <BookendsWrapper
                locale={locale}
                renderMetaWith={Helmet}
                trackingConfiguration={trackingConfiguration}
              >
                <SitewidePromoBar
                  tenant={VISTAPRINT_TENANT}
                  locale={pageContext.locale}
                  promoBarConfig={{ type: 'default' }}
                  canonicalId={canonicalId}
                  pageType={pageContext.trackingConfiguration.pageSection}
                  pageId={pageContext.pageId}
                  trackingConfiguration={{
                    pageName: pageContext.trackingConfiguration.pageName,
                    pageSection: pageContext.trackingConfiguration.pageSection,
                    pageStage: pageContext.trackingConfiguration.pageStage,
                  }}
                />
                <MainLayout>
                  <BoundedContent>
                    <Breadcrumbs data={urlMetadata} />
                  </BoundedContent>
                  {children}
                </MainLayout>
              </BookendsWrapper>
            </RootLayout>
          </PricingContextProvider>
        </AuthProvider>
      </RuntimeContextProvider>
    </LocaleProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { wrap },
  } = props;

  return wrap ? (
    <ConnectedProductPageWrapper {...props}>
      {element}
    </ConnectedProductPageWrapper>
  ) : (
    element
  );
};
